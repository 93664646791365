// extracted by mini-css-extract-plugin
export var column = "ResourceEdit__column__jIU2r";
export var container = "ResourceEdit__container__HXfC4";
export var flex = "ResourceEdit__flex__krsmD";
export var flexColumn = "ResourceEdit__flexColumn__R2JW5";
export var gap1 = "ResourceEdit__gap1__WTKnm";
export var gap2 = "ResourceEdit__gap2__VwxDD";
export var gap3 = "ResourceEdit__gap3__uUVfQ";
export var gap4 = "ResourceEdit__gap4__He34a";
export var gap5 = "ResourceEdit__gap5__KrXxj";
export var pageContainer = "ResourceEdit__pageContainer__cnUDV";
export var pageTitle = "ResourceEdit__pageTitle__NxMmg";
export var row = "ResourceEdit__row__aoOZG";