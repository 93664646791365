// extracted by mini-css-extract-plugin
export var buttonCreateContainer = "ResourceEditForm__buttonCreateContainer__sE49n";
export var column = "ResourceEditForm__column__vG7XG";
export var documentRow = "ResourceEditForm__documentRow__c_IYB";
export var editor = "ResourceEditForm__editor__pERVo";
export var fileNameText = "ResourceEditForm__fileNameText__DW7Fd";
export var fileSizeText = "ResourceEditForm__fileSizeText__Xt4e2";
export var flex = "ResourceEditForm__flex__UE5S7";
export var flexColumn = "ResourceEditForm__flexColumn__IHCFN";
export var flexContainer = "ResourceEditForm__flexContainer___g2M0";
export var gap1 = "ResourceEditForm__gap1__p9NcF";
export var gap2 = "ResourceEditForm__gap2__r9RB7";
export var gap3 = "ResourceEditForm__gap3__a3mR2";
export var gap4 = "ResourceEditForm__gap4__Hs3HK";
export var gap5 = "ResourceEditForm__gap5__KrLSj";
export var grid = "ResourceEditForm__grid__yk66I";
export var iconDelete = "ResourceEditForm__iconDelete__XI2o3";
export var iconView = "ResourceEditForm__iconView__lAiIM";
export var labelContainer = "ResourceEditForm__labelContainer__dBY25";
export var privateDownload = "ResourceEditForm__privateDownload__MgwPX";
export var resourceDetailField = "ResourceEditForm__resourceDetailField__BdNeG";
export var row = "ResourceEditForm__row__CchBF";
export var uploadDocumentButton = "ResourceEditForm__uploadDocumentButton__CGp6l";