// extracted by mini-css-extract-plugin
export var barContainer = "ResourceEditNavbar__barContainer__EMroB";
export var barWrapper = "ResourceEditNavbar__barWrapper__oLTEg";
export var column = "ResourceEditNavbar__column__Fn73w";
export var flex = "ResourceEditNavbar__flex__geyJ2";
export var flexColumn = "ResourceEditNavbar__flexColumn__G_Q1g";
export var gap1 = "ResourceEditNavbar__gap1__zWBTd";
export var gap2 = "ResourceEditNavbar__gap2__fPu8F";
export var gap3 = "ResourceEditNavbar__gap3__b2LAQ";
export var gap4 = "ResourceEditNavbar__gap4__YHEFf";
export var gap5 = "ResourceEditNavbar__gap5___1i0o";
export var linkContainer = "ResourceEditNavbar__linkContainer__WYEFl";
export var linkItem = "ResourceEditNavbar__linkItem__f_P5k";
export var row = "ResourceEditNavbar__row__dKgQn";
export var underlinedLink = "ResourceEditNavbar__underlinedLink__fdmeW";